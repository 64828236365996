/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import Modal, {
  Sheets, ModalBody,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/focus/dist/style.css';
import './NotificationWidget.css';
import IcCross from '@ingka/ssr-icon/paths/cross';
import SSRIcon from '@ingka/ssr-icon';
import { notificationUrls } from '../../services/commonService.js';

const NotificationWidget = (props:any) => {
  const {
    openNotification, closeNotification,
  } = props;

  return (

    openNotification && (
    <div style={{ zIndex: '3' }}>
      <Modal
        visible={openNotification}
        handleCloseBtn={() => closeNotification()}
      >
        <Sheets
          alignment="right"
          header={null}
          footer={null}
          className="notification-wrapper"
        >
          <ModalBody className="notificationFlex">
            <iframe
              id="frame"
              src={`${notificationUrls().notifUrl}widget`}
              allowFullScreen
              width="100%"
              height="100%"
              frameBorder="none"
            />
            <div className="notificationClose">
              <SSRIcon
                paths={IcCross}
                className="itemPointer"
                onClick={() => closeNotification()}
              />

            </div>
          </ModalBody>
        </Sheets>
      </Modal>
    </div>
    )

  );
};

export default NotificationWidget;
