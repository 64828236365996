/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isIos } from '../../common/services/commonService.js';

// Define the initial state using that type
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const initialState: any = {
  progressPercent: 0,
  syncFlag: false,
  qcCount: 0,
};

export const Reducer = createSlice({
  name: 'dashboardReducer',
  initialState,
  reducers: {
    setProgress: (state) => {
      const apiCount = isIos() ? 5 : 6;
      if (state.syncFlag === true) state.progressPercent += 100 / apiCount;
    },
    resetProgress: (state) => {
      if (state.syncFlag === true) state.progressPercent = 5;
      else state.progressPercent = 0;
    },
    setSyncFlag: (state, action: PayloadAction<boolean>) => {
      state.syncFlag = action.payload;
    },
    setQcCount: (state, action: PayloadAction<number>) => {
      state.qcCount = action.payload;
    },
  },
});

export const {
  setProgress,
  resetProgress,
  setSyncFlag,
  setQcCount,
} = Reducer.actions;

export default Reducer.reducer;
