/* eslint-disable jsx-a11y/iframe-has-title */
import SSRIcon from '@ingka/ssr-icon';
import './NotificationPopup.css';
import React from 'react';
import Modal, { ModalBody, Theatre } from '@ingka/modal';
import IcCross from '@ingka/ssr-icon/paths/cross';
import { notificationUrls } from '../../services/commonService.js';

const NotificationPopup = ({
  isVisible, onCloseModal,
}:{
  isVisible: boolean,
  onCloseModal: () => void,
}) => (
  isVisible ? (
    <div className="notificationWrapper">
      <Modal
        handleCloseBtn={onCloseModal}
        visible={isVisible}
        escapable={false}
      >
        <Theatre
          aria-labelledby="notificationModal"
        >
          <ModalBody className="notificationFlex">
            <iframe
              id="frame"
              src={`${notificationUrls().notifUrl}popup`}
              allowFullScreen
              width="100%"
              height="100%"
              frameBorder="none"
            />
            <div className="notificationClose">
              <SSRIcon
                className="itemPointer"
                paths={IcCross}
                onClick={() => onCloseModal()}
              />

            </div>
          </ModalBody>
        </Theatre>
      </Modal>
    </div>
  ) : null
);

export default NotificationPopup;
