/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Cards from '../../../../common/components/card/Card';
import './Drafts.css';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { ProjectType } from '../Dashboard';

interface DraftsProps {
    draftProjects: any[],
    handleProjectClick?: (value: any, type: ProjectType) => void;
}

const Drafts = ({
  draftProjects, handleProjectClick,
}: DraftsProps) => (
  <div className="draftContentWrapper">
    {draftProjects?.map((project) => (
      <Cards
        key={project.projectId}
        name={project.projectName}
        startDate={project.projectStartDate}
        endDate={project.projectEndDate}
        iosEndDate={false}
        hfb={isNullEmptyOrUndefined(project.hfbNos) ? false : project.hfbNos}
        items={project.itemCount}
        suAccepted={false}
        height="120px"
        onClick={() => (handleProjectClick && handleProjectClick(project, ProjectType.DRAFT))}
      />
    ))}
  </div>
);

Drafts.defaultProps = {
  handleProjectClick: () => null,
};

export default Drafts;
