/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Cards from '../../../../common/components/card/Card';
import './Accepted.css';
import {
  isIos, isRu, isSu, isNullEmptyOrUndefined,
} from '../../../../common/services/commonService.js';
import { ProjectType } from '../Dashboard';

interface AcceptedProps {
    acceptedProjects: any[],
    highlightDays: number | null;
    handleAcceptedProjectClick?: (value: any) => void,
    handleProjectClick?: (value: any, type: ProjectType) => void;
}

const Accepted = ({
  acceptedProjects,
  highlightDays,
  handleAcceptedProjectClick,
  handleProjectClick,
}: AcceptedProps) => {
  const showIosEndDate = (project: any) => {
    if (isIos()) {
      return false;
    }
    return (isNullEmptyOrUndefined(project.parentProjectEndDate) ? false
      : project.parentProjectEndDate);
  };

  const setHeight = () => {
    if (isSu()) {
      return '120px';
    }
    return '154px';
  };

  const setAcceptedCount = (project: any) => {
    if (isRu()) {
      return false;
    }
    if (isNullEmptyOrUndefined(project.accepted)) {
      return false;
    }
    return project.accepted;
  };

  return (
    <div className="acceptedContentWrapper">
      {acceptedProjects?.map((project) => (
        <Cards
          key={project.projectId}
          name={project.projectName}
          hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
          startDate={project.projectStartDate}
          endDate={project.projectEndDate}
          iosEndDate={showIosEndDate(project)}
          hfb={project.hfbNos}
          items={project.itemCount}
          suAccepted={setAcceptedCount(project)}
          highlightDays={highlightDays}
          height={setHeight()}
          onClick={() => (
            (!isRu())
              ? handleProjectClick && handleProjectClick(project, ProjectType.ACCEPTED)
              : handleAcceptedProjectClick && handleAcceptedProjectClick(project))}
        />
      ))}
    </div>
  );
};

Accepted.defaultProps = {
  handleProjectClick: () => null,
  handleAcceptedProjectClick: () => null,
};

export default Accepted;
