import React, { useState } from 'react';
import Modal, {
  Sheets, ModalHeader, ModalFooter, ModalBody,
} from '@ingka/modal';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/modal/dist/style.css';
import '@ingka/focus/dist/style.css';
import SSRIcon from '@ingka/ssr-icon';
import IcHam from '@ingka/ssr-icon/paths/menu';
import Button from '@ingka/button';
import Text from '@ingka/text';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classes from './Hamburger.module.css';
import { useTranslate } from '../../services/translationService.js';
import { appRoutes } from '../../constants/constant';
import { isSu } from '../../services/commonService.js';

const Hamburger = () => {
  const navigate = useNavigate();
  const [showHamburger, setShowHamburger] = useState(false);
  const newProject = useTranslate('button.newProject');
  const instantComparison = useTranslate('button.instantComparison');
  const dashboard = useTranslate('dashboard.title');
  const findComparison = useTranslate('button.findComparison');
  const qualityCheck = useTranslate('qualitycheck.pageTitle');

  const isNavigate = (type: string) => {
    switch (type) {
      case 'project':
        navigate(appRoutes.newProject, { state: { isNewProject: true } });
        setShowHamburger(!showHamburger);
        break;
      case 'ic':
        navigate(appRoutes.IC, { state: { isInstantCompare: true } });
        setShowHamburger(!showHamburger);
        break;
      case 'dashboard':
        navigate(
          appRoutes.dashboard,
          {
            state: {
              dashboardFilters: true,
            },
          },
        );
        setShowHamburger(!showHamburger);
        break;
      case 'fc':
        navigate(appRoutes.FC);
        setShowHamburger(!showHamburger);
        break;
      case 'qc':
        navigate(appRoutes.qualityCheck);
        setShowHamburger(!showHamburger);
        break;
      default:
        break;
    }
  };

  const buildMenuClass = (path: string) => `${classes.hamText} ${window.location.pathname === path ? classes.hamTextActive : ''}`;

  return (
    <>
      {!showHamburger && (
        <div
          onClick={() => setShowHamburger(!showHamburger)}
          onKeyUp={() => setShowHamburger(!showHamburger)}
          role="button"
          tabIndex={0}
        >
          <SSRIcon className={classes.hamIcon} paths={IcHam} />
        </div>
      )}
      {showHamburger && (
        <div style={{ zIndex: '3' }}>
          <Modal
            visible={showHamburger}
            handleCloseBtn={() => setShowHamburger(!showHamburger)}
          >
            <Sheets
              alignment="left"
              header={<ModalHeader />}
              footer={<ModalFooter />}
            >
              <ModalBody>
                {(!isMobile && !isSu()) && <Button type="secondary" className={classes.hamBtn} fluid text={newProject} onClick={() => isNavigate('project')} />}
                <Button type="secondary" className={classes.hamBtn} fluid text={instantComparison} onClick={() => isNavigate('ic')} />
                <div className={classes.hamTitle}>
                  <Text
                    className={buildMenuClass(appRoutes.dashboard)}
                    onClick={() => isNavigate('dashboard')}
                  >
                    {dashboard}
                  </Text>
                  <Text
                    className={buildMenuClass(appRoutes.FC)}
                    onClick={() => isNavigate('fc')}
                  >
                    {findComparison}
                  </Text>
                  <Text
                    className={buildMenuClass(appRoutes.qualityCheck)}
                    onClick={() => isNavigate('qc')}
                  >
                    {qualityCheck}
                  </Text>
                </div>
              </ModalBody>
            </Sheets>
          </Modal>
        </div>
      )}
    </>
  );
};

export default Hamburger;
