/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/button/dist/style.css';
import '@ingka/carousel/dist/style.css';
import '@ingka/focus/dist/style.css';
import './AllProjects.css';
import Carousel from '@ingka/carousel';
import Text from '@ingka/text';
import Button from '@ingka/button';
import { Popover } from 'antd';
import Cards from '../../../../common/components/card/Card';
import {
  isIos, isRu, isNullEmptyOrUndefined,
} from '../../../../common/services/commonService.js';
import { ProjectType } from '../Dashboard';

interface AllProjectProps {
    newProjectsReceived: any[],
    draftProjects: any[],
    acceptedProjects: any[],
    sentProjects: any[],
    closedProjects: any[],
    newProjectsReceivedTabTitle: string,
    draftTabTitle: string,
    sentTabTitle: string,
    inProgressTabTitle: string,
    acceptedTabTitle: string,
    closedTabTitle: string,
    highlightDays: number | null,
    setActiveTab: React.Dispatch<React.SetStateAction<string>>,
    handleProjectClick: (value: any, type: ProjectType) => void,
    handleAcceptedProjectClick?:(project:any) => void,

}

const AllProjects = ({
  newProjectsReceived,
  draftProjects,
  acceptedProjects,
  sentProjects,
  closedProjects,
  newProjectsReceivedTabTitle,
  draftTabTitle,
  sentTabTitle,
  inProgressTabTitle,
  acceptedTabTitle,
  closedTabTitle,
  highlightDays,
  setActiveTab,
  handleProjectClick,
  handleAcceptedProjectClick,
}: AllProjectProps) => {
  const sentInProgressTitle = () => {
    if (isIos()) {
      return sentTabTitle;
    }
    if (isRu()) {
      return inProgressTabTitle;
    }
    return sentTabTitle;
  };

  const acceptInProgressTitle = () => {
    if (isIos()) {
      return inProgressTabTitle;
    }
    if (isRu()) {
      return acceptedTabTitle;
    }
    return inProgressTabTitle;
  };

  const sortDates = (dates: string[]) => dates.sort((a, b) => {
    const aa = a.split('-').join();
    const bb = b.split('-').join();
    if (aa < bb) {
      return -1;
    }
    if (aa > bb) {
      return 1;
    }
    return 0;
  });

  const getLastReceivedDate = () => {
    const dates = newProjectsReceived.map((project) => project.receivedDate);
    if (dates.length > 0) {
      if (dates?.some((date: any) => date === 'Today')) {
        return 'Today';
      }
      if (dates?.some((date: any) => date === 'Yesterday')) {
        return 'Yesterday';
      }
      const newDates = sortDates(dates);
      return newDates.reverse()[0];
    } return null;
  };

  const onViewAllClick = (section: number) => {
    setActiveTab(`tab_${section}`);
  };

  const renderTitle = (
    section: number,
    item: any[],
    title: string,
  ) => (
    <div className="titleWrapper">
      <Text className="sectionTitle">{`${title} (${item.length})`}</Text>
      {item.length > 4 ? (
        <Button
          className="btViewAll"
          text="View all"
          type="plain"
          onClick={() => onViewAllClick(section)}
          small
        />
      ) : null}
    </div>
  );

  const showIosEndDate = (project: any) => {
    if (isIos() === true) {
      return false;
    }
    return (isNullEmptyOrUndefined(project?.parentProjectEndDate) === true ? false
      : project?.parentProjectEndDate);
  };

  const newProjectsData = () => (
    newProjectsReceived?.length > 0 ? (
      <div className="dashboardSectionWrapper newProjectsWrapper">
        {renderTitle(2, newProjectsReceived, newProjectsReceivedTabTitle)}
        <Text className="sectionSubTitle">{getLastReceivedDate()}</Text>
        <Carousel>
          {newProjectsReceived?.map((project) => (
            <Popover
              overlayClassName="allProjectsPopover"
              id={`allProjects${project.projectId.toString()}`}
              key={`allProjects${project.projectId.toString()}`}
              content={(
                <Cards
                  custom
                  name={false}
                  startDate={false}
                  endDate={false}
                  iosEndDate={false}
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  suAccepted={false}
                />
            )}
              placement="bottomLeft"
            >
              <div>
                <Cards
                  name={project.projectName}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={false}
                  hfb={false}
                  items={false}
                  suAccepted={false}
                  allProjects
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  highlightDays={highlightDays}
                  height="52px"
                  onClick={() => handleProjectClick(project, ProjectType.NEW)}
                />
              </div>
            </Popover>
          ))}
        </Carousel>
      </div>
    ) : null
  );

  const draftProjectsData = () => (
    draftProjects?.length > 0 ? (
      <div className="dashboardSectionWrapper">
        {renderTitle(3, draftProjects, draftTabTitle)}
        <Carousel>
          {draftProjects?.map((project) => (
            <Popover
              overlayClassName="allProjectsPopover"
              id={`allProjects${project.projectId.toString()}`}
              key={`allProjects${project.projectId.toString()}`}
              content={(
                <Cards
                  custom
                  name={false}
                  startDate={false}
                  endDate={false}
                  iosEndDate={false}
                  hfb={isNullEmptyOrUndefined(project.hfbNos) ? false : project.hfbNos}
                  items={project.itemCount}
                  suAccepted={false}
                />
              )}
              placement="bottomLeft"
            >
              <div>
                <Cards
                  name={project.projectName}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={false}
                  hfb={false}
                  items={false}
                  allProjects
                  suAccepted={false}
                  height="52px"
                  onClick={() => (handleProjectClick(project, ProjectType.DRAFT))}
                />
              </div>
            </Popover>
          ))}
        </Carousel>
      </div>
    ) : null
  );

  const accepetedProjectsData = () => (
    acceptedProjects?.length > 0 ? (
      <div className="dashboardSectionWrapper">
        {renderTitle(4, acceptedProjects, acceptInProgressTitle())}
        <Carousel>
          {acceptedProjects?.map((project) => (
            <Popover
              overlayClassName="allProjectsPopover"
              id={`allProjects${project.projectId.toString()}`}
              key={`allProjects${project.projectId.toString()}`}
              content={(
                <Cards
                  custom
                  name={false}
                  startDate={false}
                  endDate={false}
                  iosEndDate={showIosEndDate(project)}
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  suAccepted={project.accepted === null ? false : project.accepted}
                />
            )}
              placement="bottomLeft"
            >
              <div>
                <Cards
                  name={project.projectName}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={false}
                  hfb={false}
                  items={false}
                  allProjects
                  suAccepted={false}
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  highlightDays={highlightDays}
                  height="52px"
                  onClick={() => (!isRu() ? handleProjectClick(project, ProjectType.ACCEPTED)
                    : handleAcceptedProjectClick && handleAcceptedProjectClick(project))}
                />
              </div>
            </Popover>
          ))}
        </Carousel>
      </div>
    ) : null
  );

  const sentProjectsData = () => (
    sentProjects?.length > 0 ? (
      <div className="dashboardSectionWrapper">
        {renderTitle(5, sentProjects, sentInProgressTitle())}
        <Carousel>
          {sentProjects?.map((project) => (
            <Popover
              overlayClassName="allProjectsPopover"
              id={`allProjects${project.projectId.toString()}`}
              key={`allProjects${project.projectId.toString()}`}
              content={(
                <Cards
                  custom
                  name={false}
                  startDate={false}
                  endDate={false}
                  iosEndDate={showIosEndDate(project)}
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  suAccepted={project.accepted === null ? false : project.accepted}
                />
            )}
              placement="bottomLeft"
            >
              <div>
                <Cards
                  name={project.projectName}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={showIosEndDate(project)}
                  allProjects
                  hfb={false}
                  items={false}
                  suAccepted={false}
                  hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
                  highlightDays={highlightDays}
                  height="52px"
                  onClick={() => handleProjectClick(project, ProjectType.SENT)}
                />
              </div>
            </Popover>
          ))}
        </Carousel>
      </div>
    ) : null
  );

  const closedProjectsData = () => (
    closedProjects?.length > 0 ? (
      <div className="dashboardSectionWrapper">
        {renderTitle(6, closedProjects, closedTabTitle)}
        <Carousel>
          {closedProjects?.map((project) => (
            <Popover
              overlayClassName="allProjectsPopover"
              id={`allProjects${project.projectId.toString()}`}
              key={`allProjects${project.projectId.toString()}`}
              content={(
                <Cards
                  custom
                  name={false}
                  startDate={false}
                  endDate={false}
                  iosEndDate={showIosEndDate(project)}
                  hfb={project.hfbNos}
                  items={project.itemCount}
                  suAccepted={project.accepted === null ? false : project.accepted}
                />
            )}
              placement="bottomLeft"
            >
              <div>
                <Cards
                  name={project.projectName}
                  startDate={project.projectStartDate}
                  endDate={project.projectEndDate}
                  iosEndDate={false}
                  hfb={false}
                  items={false}
                  allProjects
                  suAccepted={false}
                  height="52px"
                  onClick={() => handleProjectClick(project, ProjectType.CLOSED)}
                />
              </div>
            </Popover>
          ))}
        </Carousel>
      </div>
    ) : null
  );

  const renderAllProjectsData = () => {
    if (isIos()) {
      return (
        <>
          {draftProjectsData()}
          {sentProjectsData()}
          {accepetedProjectsData()}
          {closedProjectsData()}
        </>
      );
    } if (isRu()) {
      return (
        <>
          {newProjectsData()}
          {draftProjectsData()}
          {accepetedProjectsData()}
          {sentProjectsData()}
          {closedProjectsData()}
        </>
      );
    }
    return (
      <>
        {newProjectsData()}
        {accepetedProjectsData()}
        {closedProjectsData()}
      </>
    );
  };

  return (
    <div className="allProjectsContentWrapper">
      {renderAllProjectsData()}
    </div>
  );
};

AllProjects.defaultProps = {
  handleAcceptedProjectClick: () => null,
};

export default AllProjects;
