/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Cards from '../../../../common/components/card/Card';
import './Sent.css';
import { isIos, isRu, isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';
import { ProjectType } from '../Dashboard';

interface SentProps {
    sentProjects: any[];
    highlightDays: number | null;
    handleProjectClick: (value: any, type: ProjectType) => void;
}

const Sent = ({
  sentProjects, highlightDays, handleProjectClick,
}: SentProps) => {
  const showIosEndDate = (project: any) => {
    if (isIos()) {
      return false;
    }
    return (isNullEmptyOrUndefined(project.parentProjectEndDate) ? false
      : project.parentProjectEndDate);
  };

  const setHeight = (project: any) => {
    if (isIos()) {
      return '154px';
    }
    if (isRu()) {
      return '188px';
    }
    return (isNullEmptyOrUndefined(project.parentProjectEndDate) ? '120px' : '154px');
  };

  return (
    <div className="sentContentWrapper">
      {sentProjects.map((project) => (
        <Cards
          key={project.projectId}
          name={project.projectName}
          startDate={project.projectStartDate}
          endDate={project.projectEndDate}
          iosEndDate={showIosEndDate(project)}
          hfb={project.hfbNos}
          items={project.itemCount}
          hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
          highlightDays={highlightDays}
          suAccepted={isNullEmptyOrUndefined(project.accepted) ? false : project.accepted}
          height={setHeight(project)}
          onClick={() => handleProjectClick(project, ProjectType.SENT)}
        />
      ))}
    </div>
  );
};

export default Sent;
