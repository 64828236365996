/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { Card } from 'antd';
import './Card.css';
import SSRIcon from '@ingka/ssr-icon';
import CardRight from '@ingka/ssr-icon/paths/chevron-right-small';
import moment from 'moment';
import {
  isIos, isNullEmptyOrUndefined, isRu,
} from '../../services/commonService.js';

const Cards = (props: any) => {
  const {
    name, startDate, endDate, iosEndDate, hfb,
    items, suAccepted, custom, cardKey, cardId,
    customClass, onClick, height, highlightDays, hasIosParent, allProjects,
  } = props;

  const dateDifference = (date: string) => {
    const eventdate = moment(date);
    const todaysdate = moment();
    return eventdate.diff(todaysdate, 'days');
  };

  const shouldHighlight = () => {
    if (!isNullEmptyOrUndefined(highlightDays) && !isIos()) {
      if (isRu()) {
        const date = hasIosParent === true ? iosEndDate : endDate;
        return dateDifference(date) >= 0 && dateDifference(date) < highlightDays;
      }
      return dateDifference(endDate) >= 0 && dateDifference(endDate) < highlightDays;
    }
    return false;
  };

  return (
    <div className="cardParentContainer">
      <Card
        key={cardKey}
        id={cardId}
        className={`${custom ? 'customCardStyle' : 'cardStyle'}
         ${customClass}
          ${shouldHighlight() ? 'highlightCard' : null}`}
        onClick={onClick}
      >
        <div className="flexRowSpace" style={{ height }}>
          <div>
            {name !== false && (
            <div className="defaultFont marginBottomFour">{name}</div>
            )}
            {startDate !== false && endDate !== false && (
            <div className="defaultSubFont">{`${startDate} - ${endDate}`}</div>
            )}
            {(allProjects !== true && (name && name.includes('IoS')) && (isRu() === true && hasIosParent === true)) && (
            <div className={`${hfb !== false ? 'marginBottomTen' : ''} ${!custom ? 'marginTopTen' : ''}`}>
              <span className="defaultFont">IoS End Date: </span>
              {iosEndDate}
            </div>
            )}
            {hfb !== false && (
            <div className={`${items !== false ? 'marginBottomTen' : ''} ${((iosEndDate === false && !custom) || ((name && !name.includes('IoS')) && (isRu() === true && hasIosParent === true))) ? 'marginTopTen' : ''}`}>
              <span className="defaultFont">HFB: </span>
              {hfb}
            </div>
            )}
            {items !== false && (
            <div className={`${suAccepted !== false ? 'marginBottomTen' : ''} ${(hfb === false && !custom) ? 'marginTopTen' : ''}`}>
              <span className="defaultFont"> Items: </span>
              {items}
            </div>
            )}
            {suAccepted !== false && (
            <div className={`${(items === false && !custom) ? 'marginTopTen' : ''}`}>
              <span className="defaultFont">
                {isIos() ? 'RUs Accepted: ' : 'SUs Accepted: '}
              </span>
              {suAccepted}
            </div>
            )}
          </div>
          {!custom ? (
            <div className="iconWrapper">
              <SSRIcon paths={CardRight} />
            </div>
          )
            : <div />}
        </div>
      </Card>
    </div>
  );
};

export default Cards;
