/* eslint-disable no-unused-expressions */
import { useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { authConfig } from '../configs/authConfig';
import { setLoader, setToast } from '../../store/reducers/create-proj-reducer';

function useCallApi() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const fetchResult = (useCallback(async () => {
    const token = await getAccessTokenSilently();
    return token;
  }, [getAccessTokenSilently]));

  const connect = async (method, apiUrl, payload, okCallback, errCallback) => {
    if (navigator.onLine) {
      axios({
        method,
        headers: {
          Authorization: `Bearer ${await fetchResult()}`,
        },
        baseURL: authConfig.backendBaseUrl,
        url: apiUrl,
        data: payload,
        responseType: 'json',
      }).then((response) => {
        if (response && response.status === 200 && response.data) {
          if (okCallback) okCallback(response.data);
        } else if (response && response.status === 401) {
          loginWithRedirect();
        } else if (errCallback) errCallback(response);
      }).catch((err) => {
        if (err?.response?.status === 401) {
          loginWithRedirect();
        } else if (errCallback) errCallback(err);
      });
    } else {
      dispatch(setLoader(false));
      !isMobile && dispatch(setToast({ openToast: true, toastLabel: 'application.internet.alert' }));
    }
  };

  const get = (apiUrl, okCallback, errCallback) => {
    connect('get', apiUrl, null, okCallback, errCallback);
  };

  const post = (apiUrl, payload, okCallback, errCallback) => {
    connect('post', apiUrl, payload, okCallback, errCallback);
  };

  const put = (apiUrl, payload, okCallback, errCallback) => {
    connect('put', apiUrl, payload, okCallback, errCallback);
  };

  const delet = (apiUrl, okCallback, errCallback) => {
    connect('delete', apiUrl, null, okCallback, errCallback);
  };

  const connectFileDownload = async (method, apiUrl, payload, okCallback, errCallback) => {
    axios({
      method,
      headers: {
        Authorization: `Bearer ${await fetchResult()}`,
      },
      baseURL: authConfig.backendBaseUrl,
      url: apiUrl,
      data: payload,
      responseType: 'blob',
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        if (okCallback) okCallback(response.data);
      } else if (errCallback) errCallback(response);
    }).catch((err) => {
      if (errCallback) errCallback(err);
    });
  };

  const fileDownload = (apiUrl, payload, okCallback, errCallback) => {
    connectFileDownload('post', apiUrl, payload, okCallback, errCallback);
  };

  const connectCompressedFile = async (method, apiUrl, payload, okCallback, errCallback) => {
    axios({
      method,
      headers: {
        Authorization: `Bearer ${await fetchResult()}`,
        ContentType: 'application/zip',
      },
      baseURL: authConfig.backendBaseUrl,
      url: apiUrl,
      data: payload,
      responseType: 'json',
    }).then((response) => {
      if (response && response.status === 200 && response.data) {
        if (okCallback) okCallback(response.data);
      } else if (errCallback) errCallback(response);
    }).catch((err) => {
      if (errCallback) errCallback(err);
    });
  };

  const postCompressedFile = (apiUrl, payload, okCallback, errCallback) => {
    connectCompressedFile('post', apiUrl, payload, okCallback, errCallback);
  };

  return {
    fetchResult, get, post, put, delet, fileDownload, postCompressedFile,
  };
}

export default useCallApi;
