export type UserSelectionType = [{
  userName: string;
  isOfUserType: UserTypes;
  userId: string;
  units?: [],
  cometitorAdmin?: string,
  superAdmin?: string,
}];

export enum UserTypes {
  IoS = 0,
  RU = 1,
  SU = 2
}

export const justNow = 'a few seconds ago';

export const faqRedirectUrl = 'https://inter.ikea.net/Toolbox/Range-and-Supply/General/Business-Solutions/Develop-the-Product-Offer-Process/ToCompete';
export const networkId = 'https://accounts.ikea.com/networkid';

export const ICRadioButtons = [
  {
    id: 'radioButton-better',
    name: 'ICRadioButtons',
    label: 'Better',
    value: 'better',
  },
  {
    id: 'radioButton-equal',
    name: 'ICRadioButtons',
    label: 'Equal',
    value: 'equal',
  },
  {
    id: 'radioButton-worse',
    name: 'ICRadioButtons',
    label: 'Worse',
    value: 'worse',
  },
];

export const ICRadioFunctionalityButtons = [
  {
    id: 'rb-better-functionality',
    name: 'ICRadioFunctionalityButtons',
    label: 'Better',
    value: 'better',
  },
  {
    id: 'rb-equal-functionality',
    name: 'ICRadioFunctionalityButtons',
    label: 'Equal',
    value: 'equal',
  },
  {
    id: 'rb-worse-functionality',
    name: 'ICRadioFunctionalityButtons',
    label: 'Worse',
    value: 'worse',
  },
];

export const YesNORadioButtons = [
  {
    id: 'radioButton-yes',
    name: 'YesNORadioButtons',
    label: 'Yes',
    value: true,
  },
  {
    id: 'radioButton-no',
    name: 'YesNORadioButtons',
    label: 'No',
    value: false,
  },
];

export const YesNoInfoRadioButtons = [
  {
    id: 'YesNoInfoRadioButtons-yes',
    name: 'YesNoInfoRadioButtons',
    label: 'Yes',
    value: true,
  },
  {
    id: 'YesNoInfoRadioButtons-no',
    name: 'YesNoInfoRadioButtons',
    label: 'No',
    value: false,
  },
];

export const PhotoRadioButtons = [
  {
    id: 'PhotoRadioButtons-photos',
    name: 'PhotoRadioButtons',
    label: 'With Photo(s)',
    value: 'photo',
  },
  {
    id: 'YesNoInfoRadioButtons-nophoto',
    name: 'PhotoRadioButtons',
    label: 'Without Photo(s)',
    value: 'noPhoto',
  },
];

export const OnlineRadioButtons = [
  {
    id: 'OnlineRadioButtons-Online',
    name: 'onlineRadioButtons',
    label: 'Online',
    value: 'online',
  },
  {
    id: 'OnlineRadioButtons-InStore',
    name: 'onlineRadioButtons',
    label: 'In-Store',
    value: 'inStore',
  },
];

export interface ComparisonTextAreaProps {
  id: number;
  title: string;
  label: string;
  charLimit: number;
  textErr:boolean;
}

export const ComparisonTextArea = {
  material: {
    id: 0,
    title: 'Material',
    label: 'About material (Optional)',
    charLimit: 600,
    textErr: false,
  },
  sustainability: {
    id: 1,
    title: 'Sustainability',
    label: 'About sustainability (Optional)',
    charLimit: 600,
    textErr: false,
  },
  comment: {
    id: 2,
    title: 'Comment',
    label: 'Your comment (Optional)',
    charLimit: 600,
    textErr: false,
  },
};

export const appRoutes = {
  home: '/',
  newProject: '/new-project',
  IC: '/instant-comparison',
  FC: '/find-comparison',
  dashboard: '/dashboard',
  outbox: '/outbox',
  RUselect: '/ru-selection',
  projDetails: '/project-details',
  HFBselect: '/hfb-selection',
  sentProjectDetails: '/sent-project',
  qualityCheck: '/quality-check',
  Unauthiorized: '/unauthorized',
};

export const projStatus = {
  draft: 'DRAFT',
  inProgress: 'INPROGRESS',
  send: 'SEND',
  closed: 'CLOSED',
  accepted: 'ACCEPTED',
};

export const uploadFileTypes = [
  'image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/apng',
  'image/avif', 'image/jfif', 'image/pjpeg', 'image/pjp', 'image/svg',
  'image/webp', 'image/bmp', 'image/ico', 'image/cur', 'image/tif',
  'image/tiff', 'image/heic', 'image/heif',
];

export const excelFileType = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

export const checkZeroRegex = /^[^1-9]+?$/;

export const checkValidCharRegex = /^[\p{L}0-9\s\n\t.,+-:'"、～：。°%（）®，()!/\r\n@]+$/u;

export const checkInvalidCharRegex = /(?=[!@#$%^&()_+\-=:;]{2})/g;

export const compPriceDiff = 50;

export const compNoOfPieces = 150;

export const pieces = {
  system: 'MET',
  code: 'pc',
  unit: 'pieces',
};

export const userTypesData = [0, 1, 2];
