/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Cards from '../../../../common/components/card/Card';
import { ProjectType } from '../Dashboard';
import './NewProjectReceived.css';
import { isNullEmptyOrUndefined } from '../../../../common/services/commonService.js';

interface NewProjectReceivedProps {
  newProjectsReceived: any[],
  highlightDays: number | null,
  handleProjectClick: (value: any, type: ProjectType) => void,
}

const NewProjectReceived = ({
  newProjectsReceived,
  highlightDays,
  handleProjectClick,
}: NewProjectReceivedProps) => (
  <div className="newProjectsContentWrapper">
    {newProjectsReceived.map((project) => (
      <Cards
        key={project.projectId}
        name={project.projectName}
        startDate={project.projectStartDate}
        endDate={project.projectEndDate}
        iosEndDate={false}
        hfb={project.hfbNos}
        items={project.itemCount}
        suAccepted={false}
        hasIosParent={!isNullEmptyOrUndefined(project?.parentProjectId)}
        highlightDays={highlightDays}
        height="120px"
        onClick={() => handleProjectClick(project, ProjectType.NEW)}
      />
    ))}
  </div>
);

export default NewProjectReceived;
